.land-gray-color {
  color: $land-link-gray-color;
}

.land-gray-100-color {
  color: $land-link-gray-100-color;
}

.land-main-color {
  color: $land-main-color;
}
.white-color {
  color: white;
}


@media (max-width: 1199px){
  .dot-wave {
    display: none !important;
  }
  .leftshape1 {
    display: none !important;
  }
  .leftshape2 {
    display: none !important;
  }
  .leftshape3 {
    display: none !important;
  }
  .rightshape1{
    display: none !important;
  }
  .rightshape2{
    display: none !important;
  }

  .feature-container-desktop{
    display: none;
  }
  .mobile_col {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 20px !important;
  }
  #feature{
    .mobile_col {
      border: 1px solid #dedede;
      border-radius: 20px;
      padding: 20px 10px !important;
    }
  }
  .font-size-38{
    font-size: 24px !important;
  }
  .font-size-50{
    font-size: 28px !important;
  }

  .land-header{
    .container-xl {
      max-width: 600px !important;
    }
    .mobile_active{
      height: 500px !important;
    }
    .navbar-header{
      .r-nav{
        display: none !important;
      }
      .l-nav {
        img {
          width: 250px;
          object-fit: contain;
          height: unset !important;
        }
      }
      .mobile-menu{
        display: flex !important;
        i {
          font-size: 25px;
        }
      }

    }
    .mobile-menu-content{

      display: flex !important;
      button {
        height: 42px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 20px;

        span{
          font-weight: bold;
          font-size: 16px;
        }

        i{
          padding-right: 7px;
          font-size: 18px;
        }
      }

      .l-login-btn{
        background-color: $land-main-color;
        border-color: $land-main-color;
        margin-left: 15px;
        width: 120px;
        margin-bottom: 20px;
      }

      .l-gd-btn{
        background-color: white;
        border-color: $land-main-color;
        color: $land-main-color !important;
        margin-left: 15px;
        width: 120px;
      }

      #mmc-ul {
        display: none;
        .active {
          color: $land-main-color;
        }

        a {
          color: $land-link-gray-color;
          padding-right: 37px;
          font-size: 18px;
          &:hover {
            color: $land-main-color;
          }
        }

        ul {
          list-style: none;
          padding-left: 0;
          li {
            padding: 15px;
          }
        }
        #land-document-mb, #land-resources-mb{
          .dropdown-toggle {
            height: unset !important;
          }
          .land-dropdown2 {
            margin-top: 0px !important;
            width: 300px !important;
          }
          .dropdown-2 .dropdown-toggle{
            padding-left: 0px !important;
          }
        }
      }
      .mmc-ul-active{
        display: block !important;
      }
    }
  }

  .main-content-land{
    section {
      padding: 40px 20px !important;
    }
    .container {
      max-width: 600px;
    }
    .chart {
      .chartbg{
        background-image: url("../../../images/land/chartbg-mb.png") !important;
        aspect-ratio: 1 / 0.5 !important;
        border-radius: 20px;
        .chart-offset {
          display: none;
        }
        .chart-r-card{
          padding-left: 5%;
        }

      }
    }
    .tm-desktop {
      display: none;
    }

  }
  #ready{
    .ready-bg {
      background-image: url("../../../images/land/ready-mb.png") !important;
      aspect-ratio: 1 / 0.42 !important;
      border-radius: 20px;
    }
  }
  #func {
    .mobile_col {
      margin-bottom: 20px;
    }
  }
  #driver_license {
    img {
      width: 70% !important;
    }
  }


}

@media (min-width: 1200px){

  .col-xl-20 {
    flex: 0 0 auto;
    width: 20%;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .main-content-land {

    #func {
      .container {
        max-width: 1700px;
        padding: 0 30px;

        .odd {
          padding-top: 24%;
        }
      }
    }
    .chart {
      .container {
        max-width: 1700px;
        padding: 0 30px;
      }
    }
    .feature-container-mobile{
      display: none;
    }
    .tm-mobile {
      display: none;
    }

    .container {
      .pr-6{
        padding-right: 70px !important;
      }
      .pl-6{
        padding-left: 70px !important;
      }
    }

  }

  .land-header{
    .navbar-header{
      .r-nav{
        #land-document, #land-resources {
          .land-dropdown2{
            margin-top: 0px !important;
            width: 250px;
            .document-sub-menu{
              padding: 10px 20px 0px 20px;
              li{
                padding: 8px 0;
                a {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .f_wave{

    /*-webkit-animation: feature_wave 4s linear infinite;
    animation: feature_wave 4s linear infinite;*/

  }
  .f_wave_odd{
    /*-webkit-animation: feature_wave_odd 4s linear infinite;
    animation: feature_wave_odd 4s linear infinite;*/
  }

  .dot-wave {
    position: absolute;
    top: -80px;
    left: -80px;
    z-index: -1;
    /*animation: bounce-dot 3s linear infinite;*/
  }

  .leftshape1 {
    position: absolute;
    top: 150px;
    left: 0px;
    z-index: -1;
    /*animation: bounce 2s linear infinite;*/
  }
  .leftshape2 {
    position: absolute;
    top: 1350px;
    left: 0px;
    z-index: -1;
    /*animation: bounce 2s linear infinite;*/
  }
  .leftshape3 {
    position: absolute;
    top: 2550px;
    left: 0px;
    z-index: -1;
    /*animation: bounce 2s linear infinite;*/
  }
  .rightshape1{
    position: absolute;
    top: 750px;
    right: 0px;
    z-index: -1;
    /*animation: bounce 2s linear infinite;*/
  }
  .rightshape2{
    position: absolute;
    top: 1950px;
    right: 0px;
    z-index: -1;
    /*animation: bounce 2s linear infinite;*/
  }
}

@media (min-width: 1400px){
  #intro {
    .stick {
      border-left: 3px solid $land-main-color;
      padding-left: 7px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}



.main-content-land{
  body h1, h2, h3, h4, h5, h6, span, div, p, a, button, textarea, input, select, label {
    font-family: 'IBM Plex Sans' !important;
  }


  padding-top: 120px;
  section {
    position: relative;
    padding: 80px 0px;
  }
  button{
    height: 42px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 30px !important;
    span{
      font-weight: bold;
      font-size: 16px;
    }
    i{
      padding-right: 7px;
      padding-left: 7px;
      font-size: 18px;
    }
  }
  .bluebtn{
    background-color: $land-main-color !important;
      border-color: $land-main-color !important;
  }
  .whitebtn{
    background-color: white;
    border-color: $land-main-color;
    color: $land-main-color !important;
  }
  .transbtn {
    background-color: transparent;
    border-color: white;
    color: white !important;
  }
  .rightibtn{
    span{
      padding-left: 8px;
    }
    i{
      padding-right: 0px !important;
    }

  }
  .bookbtn{
    i{
      animation: demo-icon 1s linear infinite;
    }
  }
  #ready{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    .ready-bg {
      background-image: url("../../../images/land/ready.svg");
      aspect-ratio: 1 / 0.28;
      background-size: cover;
      background-position: center;
    }
  }


  #func {
    background-color: #F2FAFF;
  }
  #feedback {

  }
  #testmonial {
    .react-star-rate__star__first{
      font-size: 24px;
    }
    .react-star-rate__star__second{
      font-size: 24px;
    }
    .feedback-card{
      height: 315px;
      box-shadow: rgba($black, 0.1) 0px 1px 5px -1px, rgba($black, 0.1) 0px 2px 10px 0px, rgba($black, 0.1) 0px 1px 18px 0px;
      border: unset !important;
      border-radius: 10px;
    }
    .carousel-control-prev{
      display: none !important;
    }
    .carousel-control-next {
      display: none !important;
    }
    .react-star-rate{
      margin-left: -5px !important;
      margin-bottom: 20px;
    }
    .tm-wrapper {
      width: 80%;
    }
  }
  #product_demo{

  }

  .chart {
    .chartbg{
      background-image: url("../../../images/land/chartbg.svg");
      aspect-ratio: 1 / 0.23;
      background-size: cover;
      background-position: center;
    }
  }

  #model {
    .card_models{
      height: 315px;
      background-color: #F2FAFF;
      text-align: center;
      border-radius: 20px;
      padding: 15px;
      margin-bottom: 25px;
      img {
        width: 30%;
      }
    }
  }

  #driver_license{
    img {
        width: 40%;
      }
    padding-bottom: 0px !important;
    .license-box{
      padding-bottom: 80px;
      border-bottom: 1px solid #D9D9D9;
    }
    .license_info{
      background-color: #F2FAFF;
      border-radius: 15px;
      padding: 20px 30px;
      margin-bottom: 20px;
    }
  }
  #tech {
      .tech_support_action {
        width: 724px;
      }
      .learn {
        &:hover{
          color: $land-main-color !important;
        }
      }

      table {
        width: 724px;
        max-width: 900px;

        thead{
          height: 75px;
          background-color: $land-main-color;
          .fch {
              text-align: center;
            }
          th{
            padding: 25px 20px;
            color: white;
            border: 1px solid #CDE5FA;
            font-size: 15px;
          }
        }
        tbody{
          tr {
            .fch {
              text-align: center;
              color: #2D2828;
            }

            td {
              padding: 13px 20px;
              color: #5E7486;
              border: 1px solid #CDE5FA;
              font-size: 15px;
            }
          }
        }
      }
    }
}

.land-header{
  body h1, h2, h3, h4, h5, h6, span, div, p, a, button, textarea, input, select, label {
    font-family: 'IBM Plex Sans' !important;
  }
  background-color: white !important;

  #menud {
  }

  .container{

  }
  .mobile-menu-content{
      display: none;
    }
  .navbar-header{
    margin-top: 13px;
    margin-bottom: 13px;
    height: 42px;
    padding: 0;

    img {
      height: 100% !important;
    }

    .mobile-menu{
      display: none;
    }


    .r-nav{
      .active {
        color: $land-main-color;
      }

      a {
        color: $land-link-gray-color;
        padding-right: 37px;
        font-size: 15px;
        &:hover {
          color: $land-main-color;
        }
      }
      .l-login-btn{
        background-color: $land-main-color;
        border-color: $land-main-color;
        margin-right: 15px;
        width: 120px;
      }
      .l-gd-btn{
        background-color: white;
        border-color: $land-main-color;
        color: $land-main-color !important;
        width: 120px;
      }
      button {
        height: 36px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 16px;

        span{
          font-weight: bold;
          font-size: 15px;
        }

        i{
          padding-right: 7px;
          font-size: 15px;
        }
      }
    }
  }
}

.land-footer{
  body h1, h2, h3, h4, h5, h6, span, div, p, a, button, textarea, input, select, label {
    font-family: 'IBM Plex Sans' !important;
  }

    background-image: url("../../../images/land/footerbg.svg");
    height: 580px;
    background-size: cover;
    background-position: center;
    text-align: center;

    .f-box{
      max-width: 1360px;
      margin:auto
    }

    img {
      width: 300px;
      margin-top: 180px;
    }

    .f-divider{
      border-top: 1px solid white;
    }
}


@keyframes feature_wave {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes feature_wave_odd {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounce-dot {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes demo-icon {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}