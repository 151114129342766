body h1, h2, h3, h4, h5, h6, span, div, p, a, button, textarea, input, select, label {
  font-family: sans-serif !important;
}
body {
  background-color: #fff !important;
}
body label{
  font-weight: 500;
}

body h1, h2, h3, h4, h5, h6, p {
  color: $gray-800;
}

.step-title {
  font-weight: 500 !important;
  color: $gray-800 !important;
}
.tour-title {
  font-weight: 500 !important;
  color: $gray-900 !important;
}
.form-label {
  display: flex;
  color: $gray-800 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #cbd0d3;
  border-radius: 8px;
}
thead {
  &:hover {
    cursor: pointer;
  }
}
.hand{
  cursor: pointer;
}
.span-red{
  color: $red;
}

.font-w-600 {
  font-weight: 600;
}
.font-w-500 {
  font-weight: 500;
}
.font-w-400 {
  font-weight: 400;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-35{
  font-size: 35px;
}
.font-size-38{
  font-size: 38px;
}
.font-size-40{
  font-size: 40px;
}
.font-size-80 {
  font-size: 80px;
}
.font-size-45{
  font-size: 45px;
}
.font-size-50 {
  font-size: 50px;
}

.px-15 {
  padding-left: 24px;
  padding-right: 24px;
}
.card-title-x {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}
.d-n {
  display: none !important;
}
.d-n-nimp{
  display: none;
}
.text-logo {
  font-size: 18px;
  color: white;
}

.select3 {
  border: 0px !important;
  background-color: transparent !important;
  padding-left: 4px !important;
  background-position: right 3px center !important;

  &:focus {
    box-shadow: none !important;
  }
}

.select3-dv {
  width: 100%;
}
.select3-li {
  a{
    &:hover i{
      color: $sidebar-menu-item-icon-color !important;
    }
  }
}

.dropdown-menu-2{
  z-index: 1050;
  max-height: 580px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 7px;
  border-radius: 10px;
  width: 180px;

  button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.dropdown-2{
  .dropdown-toggle {
    background-color: transparent !important;
    border: 0px;
    width: 100%;
    text-align: left;
    padding-right: 0px;
    padding-left: 3px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    color: $gray-800 !important;
    &:focus {
      box-shadow: none !important;
      background-color: transparent !important;
    }
    &:active {
      box-shadow: none !important;
      background-color: transparent !important;
    }
    i {
      float: right;
      padding-bottom: 0px !important;
    }
  }
}

.actionbtn {
  cursor: pointer;
  &:hover{
    color: white !important;
  }

  i {
    font-size: 18px;
  }
}


.float-right {
  float: right;
}
.text-right {
  text-align: right !important;
}

.custom-table {
  th,
  td {
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom: $table-border-width solid #dee4e7;
    .inntertd{
      font-size: 13px;
      color: #505d69;
    }
  }
}
.custom-table thead {
  background-color: #f2f5f6;
  th {
    padding: 10px 15px !important;
  }
}
.custom-table .table > :not(:first-child) {
    border-top: $table-border-width solid #dee4e7;
}
.custom-table .table > :not(caption) > * > * {
    padding: 15px;
}
.custom-table {
  .purple {
    &:hover {
      color: $purple !important;
    }
  }
  .red {
    &:hover {
      color: $red !important;
    }
  }
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.react-bootstrap-table{
  overflow-x: scroll;
}
.react-bootstrap-table th .order > .dropup > .caret {
    margin: 10px 0;
    color: #cccccc;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}
.react-bootstrap-table th .order > .dropdown > .caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}
.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
    margin: 10px 6.5px;
}

.expiry_item {
  width: 75px;
  display: inline-block;
}

.model-card {
  cursor: pointer;
  &:hover {
    box-shadow: rgba($black, 0.1) 0px 1px 5px -1px, rgba($black, 0.1) 0px 2px 10px 0px, rgba($black, 0.1) 0px 1px 18px 0px;
  }
}
.model-check-i {
  font-size: 17px;
  width: 18px;
  height: 18px;
  display: none;
  float: right;
}
.model-card-dv {
  width: 300px;
  display: block;
  flex: 0 0 auto;
}
.model-card-dv .card-body{
  height: 268px;
}
.model-input-dv {
  width: 600px;
  display: block;
  flex: 0 0 auto;
}

.dashboard i {
  color: $primary
}
.elip {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}
.elip-5 {
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.elip-3 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.elip-1 {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.react-bootstrap-table-pagination{
  margin-top: 10px;
}
.react-bootstrap-table-pagination #pageDropDown{
  background-color: $primary;
  border: 0px;
  margin-right: 10px;
  padding: 0.2rem 0.7rem;
}
.react-bootstrap-table-pagination-list ul {
  float: right;
  margin-top: 6px;
}
.react-bs-table-sizePerPage-dropdown ul {
  margin-top: 6px;
}
.page-link {
  padding: 0.15rem 0.65rem;
  border: 1px solid #cbced3;
  background-color: #f8f8f8;
  &:focus{
    box-shadow: none;
  }
  &:hover{
    color: #74788d;
  }
}
.page-item.disabled .page-link{
  border-color: #d9dadb;
  background-color: #f8f8f8;
}
.react-bs-table-no-data{
  text-align: center;
  padding: 1.45rem 0.75rem !important;
}

#vertical-menu-btn {
  padding-top: 1px;
  padding-bottom: 6px;
}

.tbindex {
  position: relative; z-index: 1050; display: none;
}

.warning2rect {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(238, 162, 54); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box; animation: 0.75s ease 0s infinite alternate none running pulseWarning;

  .wn2ic1 {
  position: absolute; width: 5px; height: 47px; left: 50%; top: 10px; border-radius: 2px; margin-left: -2px; background-color: rgb(240, 173, 78); animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
  }

  .wn2ic2 {
    position: absolute; width: 7px; height: 7px; border-radius: 50%; margin-left: -3px; left: 50%; bottom: 10px; background-color: rgb(240, 173, 78); animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
  }
}

.success2rect {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(76, 174, 76); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box;

  .sc2ic1 {
    border-radius: 120px 0px 0px 120px; position: absolute; width: 60px; height: 100px; background: white; transform: rotate(-45deg); top: -7px; left: -33px; transform-origin: 60px 60px;
  }

  .sc2ic2 {
    height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 25px; left: 14px; top: 46px; transform: rotate(45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessTip;
  }

  .sc2ic3 {
    height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 47px; right: 8px; top: 38px; transform: rotate(-45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessLong;
  }

  .sc2ic4 {
    width: 80px; height: 80px; border: 4px solid rgba(92, 184, 92, 0.2); border-radius: 50%; box-sizing: content-box; position: absolute; left: -4px; top: -4px; z-index: 2;
  }

  .sc2ic5 {
    width: 5px; height: 90px; background-color: rgb(255, 255, 255); position: absolute; left: 28px; top: 8px; z-index: 1; transform: rotate(-45deg);
  }

  .sc2ic6{
    border-radius: 0px 120px 120px 0px; position: absolute; width: 60px; height: 120px; background: white; transform: rotate(-45deg); top: -11px; left: 30px; transform-origin: 0px 60px; animation: 4.25s ease-in 0s 1 normal none running rotatePlaceholder;
  }
}
#loading {
  display: none;
}
.loadingspin {
  position: absolute;
  left: 50%;
  top: 30%;
}

.ctooltip {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

.ctooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #4c4f52;
  border: 1px solid #dee1e3;
  text-align: left;
  border-radius: 6px;
  padding: 5px 15px;
  top: 0px;
  left: 25px;
  opacity: 0.9;
  transition: opacity 0.3s;

  /* Position the tooltip */
  position: absolute;
  z-index: 1050;
}
.ctooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.ctooltip:hover .tooltiptext {
  visibility: visible;
  font-size: 13px;
  color: white;
}


.more {
  padding: 0.2rem 0.45rem !important;
}
.policy_price_inputgr .form-group {
  flex: 1 1 auto !important;
}

.yellow {
  color: $yellow !important;
}
.red {
  color: $red !important;
}
.pink {
  color: $pink !important;
}
.purple {
  color: $purple !important;
}
.disabled_gray {
  color: $gray-500 !important;
}

.font-weight-bold {
  font-weight: bold;
}

.dk-desc {
  color: $gray-800;
}
.cswitch{
  cursor: pointer;
  width: 35px !important;
}
.li-menu-title{
  text-transform: unset !important;
}
.li_classifier_project {
  opacity: 1 !important;
  span {
    font-size: 13px;
  }
}
#sidebar-menu .li-classifier_line{
  padding: 5px 20px 0px !important;
}
.classifier_line{
  border-bottom: 1px solid #c5cacf;
}
.li-settings{
  color: $gray-800;
  opacity: 1 !important;
  span {
    font-size: 24px;
  }
}
.dash-icon {
  margin-top: -2px;
}
.c-diamond-sz {
  font-size: 16px !important;
}
.appsearch {
  position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: #74788d;
}

.authentication-page-content{
  margin-top: -150px;
}

.profile-page-content{
  margin-top: -150px;
}

.c-pubpage-signin {
  border: 1px solid #cfcfcf;
  border-radius: 12px;
  background-color: #fff !important;
}
.c-pubpage-signup {
  border: 1px solid #cfcfcf;
  border-radius: 12px;
  background-color: #fff !important;
}

.pub-header{
  height: 280px;
}
.preprocess-header{
  height: $header-height;
  background-color: $main-color;
  align-items: center;
    padding-left: 30px;
    display: flex;
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
}
.pub-title-logo{
  padding: 32px; position: absolute; top: 0px; left: 0px;
  color: white;
  font-size: 18px;
}
.loginform{
  margin: 0px 40px;
}

.text-left {
  text-align: left!important;
}

.pl-2 {
  padding-left:0.5rem !important;
}
.pr-2 {
  padding-right:0.5rem !important;
}
.pr-0 {
  padding-right:0px !important;
}

.p-30{
  padding: 30px !important;
}
.loginform img{
  width: 250px;
}

.logincol {
  width: 670px;
}

.mtimg {
  width: 350px;
}
.selectedSpan{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 85%;
    font-size: 13px;
}
.piediv {
  height: 295px;
}

.preprocessform{
  margin-top: calc(33vh);
}

.emailverifylogo {
  margin-left: 22px;
}
.display-center{
  margin-left: calc(33.3vw - 200px);
}

.addinto-step-row{
  margin-top: 7vh;
}
.mtrow{
  margin-top: 9vh;
}

#basic-pills-wizard .nav-link{
  &:hover {
    cursor: default !important;
  }
}
.bottom-menu{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.s-in-td{
  padding: 0px 20px;
  max-width: 150px !important;
}

.clogintitle{
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  padding-bottom: 9px;
  padding-top: 15px;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.testimonial-content-col{
  max-width: 300px;
  white-space: break-spaces !important;
}
// --------------------------------------- manual hotspot --------------------------------------- //
.project_li_hs{
  right: 0;
  margin-top: -45px;
  position: absolute;
}
.project_create_drop_hs{
  right: 0;
  margin-top: -63px;
  position: absolute;
}
.project_create_hs{
  left: 25px;
  margin-top: 2px;
  position: absolute;
}
// --------------------------------------- * --------------------------------------- //

// --------------------------------------- twinkling border --------------------------------------- //
.project_view_hs{
  border:3px solid #ff926b;
  padding: 8px 4px 4px 4px;
  border-radius: 16px;
  animation: project_view_hs 0.8s step-end infinite;
}
@-webkit-keyframes project_view_hs {
    from, to {
        border-color: #f9b59d
    }
    50% {
        border-color: #f3d2c6
    }
}
@keyframes project_view_hs {
    from, to {
        border-color: #f9b59d
    }
    50% {
        border-color: #ff926b
    }
}
// --------------------------------------- * --------------------------------------- //


// --------------------------------------- twinkling border 2--------------------------------------- //
$white: #fff;
$slack-size: 18px;
$slack-ring-size: $slack-size * 2;
$slack-ring-border: 4px;
$slack-increment: 10px;
@mixin square ($size: 18px) {
  width: $size;
  height: $size;
}
@mixin round ($size: 18px) {
  @include square($size);
  border-radius: 50%;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes slack {
  from {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.slack {
  @include round($slack-size);

  position: relative;
  background-color: transparent;

  &:before {
    @include center;
    @include round($slack-ring-size);

    content: "";
    display: block;
    border: $slack-ring-border solid $white;
    box-shadow: inset 0 0 4px 2px rgba($black, 1),
      0 0 4px 2px rgba($black, 1);
    animation: slack 300ms linear infinite;
    animation-direction: alternate;
  }
}

// --------------------------------------- * --------------------------------------- //


// --------------------------------------- intro.js custom style definition --------------------------------------- //
.ux_model_create_form_tooltip{
  bottom: -210px !important;
  right: 0px !important;
  left: unset !important;
  top: unset !important;
  .introjs-tooltip-header{
  }
  .introjs-tooltipbuttons{
    .introjs-button{
      padding: 2px 8px !important;
    }
  }
}
.introglobaltooltipClass, .paymentglobaltooltipClass, .projectglobaltooltipClass, .modelglobaltooltipClass, .tourtoprojectglobaltooltipClass, .tourtomodelglobaltooltipClass{
  .introjs-tooltip-header{
  }
  .introjs-tooltipbuttons{
    .introjs-button{
      padding: 2px 8px !important;
    }
  }
}
.introglobalhighlightClass, .paymentglobalhighlightClass, .projectglobalhighlightClass, .modelglobalhighlightClass, .tourtoprojectglobalhighlightClass, .tourtomodelglobalhighlightClass {
  pointer-events: none !important;
  box-shadow: 0px 0px 0px 0px rgba($black, 0.8 ), 0px 0px 0px 5000px rgba(#212121, 0.5)  !important;
}

.introjs-overlay{
  z-index: unset !important;
}

#ux_regeneratetoken_modal .introjs-showElement {
  box-shadow: 0px 0px 4px 4px rgba(#e3e3e3, 0.8);
}


.tour .dropdown-menu{
  width: 320px;
}
.tourcard {
  margin-bottom: 0px !important;
  border: 0px !important;

  .card-body{
    padding: 0px 22px !important;
  }
}
// --------------------------------------- * --------------------------------------- //

// --------------------------------------- documentation --------------------------------------- //
.project-doc{
  .markdown-body pre{
    background-color: #eff1f3 !important;
  }
  .code-markdown-body{
    padding: 0px !important;
    background-color: #eff1f3 !important;
    font-size: 14px !important;
    flex-direction: row;
    overflow-y: unset !important;
    pre {
      background-color: transparent !important;
      flex-grow: 1;
      padding: 20px 20px 20px 0px !important;
      font-size: 14px !important;
      line-height: 1.5;
      code {
        padding: 0px !important;
        font-size: 14px !important;
        overflow-x: visible;
      }
    }
    .line-number{
      padding-right: 20px;
      width: 60px;
      font-size: 13px;
      display: inline-block;
      direction: rtl;
      color: #808080;
    }
    .code-markdown-lines{
      width: 32px;
      flex-grow: 0;
      flex-shrink: 0;
      padding-left: 8px;
      color: #808080;
      text-align: right;
      white-space: pre;
      letter-spacing: -1px;
    }
  }
  .card-body{
    padding: 0px !important;
  }
  .d-header{
    padding: 10px 14px;
    background-color: #eff2f7;
  }
  .d-content{
    height: 728px;
    padding: 10px 20px;
    overflow-y: scroll;
    h2{
      font-size: 16px;
      font-weight: 500 !important;
    }
    h1{
      font-size: 22px;
      font-weight: 500 !important;
    }
    a{
      color: $purple;
    }
  }
  .dropdown {
    .selectlgbtn{
      padding: 0px !important;
      &:focus{
        box-shadow: unset !important;
      }
    }
  }
  .dropdown-doc{
    margin-top: 10px;
  }
  .doc-tab{
    span{
      padding: 2px 10px;
    }
    .active{
      border-bottom: 2px solid;
    }
  }
}

// --------------------------------------- * --------------------------------------- //


// --------------------------------------- not using --------------------------------------- //
.profilebtn{
  padding-left: 0px !important;
}

.hotspot_img{
  width: 100%;
}
.ux_hotspot_d{
  position: absolute;
  top: 6px;
  left: 26px;
  width: 25px;
}
.modal-sm{
  max-width: 350px !important;
  width: 350px;
  .modal-header{
    .close{
      padding: 28px 20px !important;
    }
  }
}
.welcomemodal .modal-header{
  button {
    display: none;
  }
  .modal-title{
    width: 100%;
  }
}

.react-joyride__overlay{
  z-index: 100000 !important;
}
.__floater__open{
  z-index: 100000 !important;
}
.ml-pcl .popover{
  margin-left: 5px;
}
.ml-pcd .popover{
  margin-left: 180px;
}
.popover {
  border: 1px solid #dee1e3;
}

.project_create_btn{
  width: 150px;
}
.hotspot_btn{
  background-color: transparent !important;
  border: 0;
  padding: 0;
  &:focus {
    box-shadow: unset !important;
  }
}

// --------------------------------------- * --------------------------------------- //
@media (max-width: 623px) {
  .model-card-dv {
    width: 100%;
  }
}

